export const countries = {
    AFG: 'AFGHANISTAN',
    ALB: 'ALBANIA',
    DZA: 'ALGERIA',
    ASM: 'AMERICAN SAMOA',
    AND: 'ANDORRA',
    AGO: 'ANGOLA',
    AIA: 'ANGUILLA',
    ATA: 'ANTARCTICA',
    ATG: 'ANTIGUA AND BARBUDA',
    ARG: 'ARGENTINA',
    ARM: 'ARMENIA',
    ABW: 'ARUBA',
    AUS: 'AUSTRALIA',
    AUT: 'AUSTRIA',
    AZE: 'AZERBAIJAN',
    BHS: 'BAHAMAS',
    BHR: 'BAHRAIN',
    BGD: 'BANGLADESH',
    BRB: 'BARBADOS',
    BLR: 'BELARUS',
    BEL: 'BELGIUM',
    BLZ: 'BELIZE',
    BEN: 'BENIN',
    BMU: 'BERMUDA',
    BTN: 'BHUTAN',
    BOL: 'BOLIVIA',
    BIH: 'BOSNIA AND HERZEGOVINA',
    BWA: 'BOTSWANA',
    BVT: 'BOUVET ISLAND',
    BRA: 'BRAZIL',
    IOT: 'BRITISH INDIAN OCEAN TERRITORY',
    GBN: 'BRITISH NATIONAL OVERSEAS',
    GBO: 'BRITISH OVERSEAS CITIZENS',
    GBP: 'BRITISH PROTECTED PERSON',
    GBS: 'BRITISH SUBJECT',
    BRN: 'BRUNEI DARUSSALAM',
    BGR: 'BULGARIA',
    BFA: 'BURKINA FASO',
    BDI: 'BURUNDI',
    KHM: 'CAMBODIA',
    CMR: 'CAMEROON',
    CAN: 'CANADA',
    CPV: 'CAPE VERDE',
    CYM: 'CAYMAN ISLANDS',
    CAF: 'CENTRAL AFRICAN REPUBLIC',
    TCD: 'CHAD',
    CHL: 'CHILE',
    CHN: 'CHINA',
    CXR: 'CHRISTMAS ISLAND',
    CCK: 'COCOS ISLANDS',
    COL: 'COLOMBIA',
    COM: 'COMOROS',
    COG: 'CONGO',
    COK: 'COOK ISLANDS',
    CRI: 'COSTA RICA',
    CIV: 'COTE D\'IVOIRE',
    HRV: 'CROATIA',
    CUB: 'CUBA',
    CYP: 'CYPRUS',
    CZE: 'CZECH REPUBLIC',
    PRK: 'DEMOCRATIC PEOPLE\'S REPUBLIC OF KOREA',
    DPR: 'DEMOCRATIC PEOPLES REPUBLIC OF KOREA',
    COD: 'DEMOCRATIC REPUBLIC OF THE CONGO',
    DNK: 'DENMARK',
    DJI: 'DJIBOUTI',
    DMA: 'DOMINICA',
    DOM: 'DOMINICAN REPUBLIC',
    ECU: 'ECUADOR',
    EGY: 'EGYPT',
    SLV: 'EL SALVADOR',
    GNQ: 'EQUATORIAL GUINEA',
    ERI: 'ERITREA',
    EST: 'ESTONIA',
    ETH: 'ETHIOPIA',
    EUE: 'EUROPEAN UNION LAISSER PASSER',
    FLK: 'FALKLAND ISLAND (MALVINAS)',
    FRO: 'FAROE ISLANDS',
    FJI: 'FIJI',
    FIN: 'FINLAND',
    FXX: 'FINLAND, METROPOLITAN',
    FRA: 'FRANCE',
    GUF: 'FRENCH GUIANA',
    PYF: 'FRENCH POLYNESIA',
    ATF: 'FRENCH SOUTHERN TERRITORIES',
    GAB: 'GABON',
    GMB: 'GAMBIA',
    GEO: 'GEORGIA',
    DGR: 'GERMANY',
    GHA: 'GHANA',
    GIB: 'GIBRALTAR',
    GRC: 'GREECE',
    GRL: 'GREENLAND',
    GRD: 'GRENADA',
    GLP: 'GUADELOUPE',
    GUM: 'GUAM',
    GTM: 'GUATEMALA',
    GNB: 'GUIENA-BISSAU',
    GIN: 'GUINEA',
    GUY: 'GUYANA',
    HTI: 'HAITI',
    HMD: 'HEARD AND MCDONALD ISLANDS',
    VAT: 'HOLY SEE (VATICAN CITY STATE)',
    HND: 'HONDURAS',
    HKG: 'HONG KONG',
    HUN: 'HUNGARY',
    ISL: 'ICELAND',
    IND: 'INDIA',
    IDN: 'INDONESIA',
    IRN: 'IRAN',
    IRQ: 'IRAQ',
    IRL: 'IRELAND',
    ISR: 'ISRAEL',
    ITA: 'ITALY',
    JAM: 'JAMAICA',
    JPN: 'JAPAN',
    JOR: 'JORDAN',
    KAZ: 'KAZAKHSTAN',
    KEN: 'KENYA',
    KIR: 'KIRIBATI',
    KWT: 'KUWAIT',
    KGZ: 'KYRGYZSTAN',
    LAO: 'LAO PDR',
    LVA: 'LATVIA',
    LBN: 'LEBANON',
    LSO: 'LESOTHO',
    LBR: 'LIBERIA',
    LBY: 'LIBYA',
    LIE: 'LIECHTENSTEIN',
    LTU: 'LITHUANIA',
    LUX: 'LUXEMBOURG',
    MAC: 'MACAU',
    MDG: 'MADAGASCAR',
    MWI: 'MALAWI',
    MYS: 'MALAYSIA',
    MDV: 'MALDIVES',
    MLI: 'MALI',
    MLT: 'MALTA',
    MHL: 'MARSHALL ISLANDS',
    MTQ: 'MARTINIQUE',
    MRT: 'MAURITANIA',
    MUS: 'MAURITIUS',
    MYT: 'MAYOTTE',
    MEX: 'MEXICO',
    FSM: 'MICRONESIA, FEDERATED STATES OF',
    MCO: 'MONACO',
    MNG: 'MONGOLIA',
    MNE: 'MONTENEGRO',
    MSR: 'MONTSERRAT',
    MAR: 'MOROCCO',
    MOZ: 'MOZAMBIQUE',
    MMR: 'MYANMAR',
    NAM: 'NAMIBIA',
    NRU: 'NAURU',
    NNS: 'NEGARA-NEGARA SUMBER',
    NPL: 'NEPAL',
    NLD: 'NETHERLANDS',
    ANT: 'NETHERLANDS ANTILLES',
    NTZ: 'NEUTRAL ZONE',
    NCL: 'NEW CELEDONIA',
    NZL: 'NEW ZEALAND',
    NIC: 'NICARAGUA',
    NER: 'NIGER',
    NGA: 'NIGERIA',
    ZZD: 'NIL',
    NIU: 'NIUE',
    NFK: 'NORFOLK ISLAND',
    MNP: 'NORTHERN MARIANA ISLANDS',
    NOR: 'NORWAY',
    OMN: 'OMAN',
    PAK: 'PAKISTAN',
    PLW: 'PALAU',
    PAL: 'PALESTINE',
    PSE: 'PALESTINE',
    PAN: 'PANAMA',
    PNG: 'PAPUA NEW GUINEA',
    PRY: 'PARAGUAY',
    PER: 'PERU',
    PHL: 'PHILIPPINES',
    PCN: 'PITCAIRN ISLAND',
    POL: 'POLAND',
    PRT: 'PORTUGAL',
    PRI: 'PUERTO RICO',
    QAT: 'QATAR',
    XXC: 'REFUGEE',
    XXB: 'REFUGEE ARTICLE 1/1951',
    MDA: 'REP OF MOLDOVA',
    RKS: 'REP. KOSOVO',
    KOR: 'REPUBLIC OF KOREA',
    KOS: 'REPUBLIC OF KOSOVO',
    MKD: 'REPUBLIC OF MACEDONIA',
    ROS: 'REPUBLIC OF SOMALILAND',
    SSD: 'REPUBLIC OF SOUTH SUDAN',
    REU: 'REUNION',
    ROM: 'ROMANIA',
    ROU: 'ROMANIA',
    RUS: 'RUSSIA',
    RWA: 'RWANDA',
    SHN: 'SAINT HELENA',
    KNA: 'SAINT KITTS AND NEVIS',
    LCA: 'SAINT LUCIA',
    SPM: 'SAINT PIERRE AND MIQUELON',
    VCT: 'SAINT VINCENT AND THE GRENADINES',
    WSM: 'SAMOA',
    SMR: 'SAN MARINO',
    STP: 'SAO TOME AND PRINCIPE',
    SAU: 'SAUDI ARABIA',
    SEN: 'SENEGAL',
    SER: 'SERBIA',
    SRB: 'SERBIA',
    SCG: 'SERBIA AND MONTENEGRO',
    SYC: 'SEYCHELLES',
    SLE: 'SIERRA LEONE',
    SGP: 'SINGAPORE',
    SVK: 'SLOVAKIA',
    SVN: 'SLOVENIA',
    SLB: 'SOLOMON ISLANDS',
    SOM: 'SOMALIA',
    ZAF: 'SOUTH AFRICA',
    SGS: 'SOUTH GEORGIA (THE SOUTH SANDWICH IS)',
    ESP: 'SPAIN',
    LKA: 'SRI LANKA',
    ZZA: 'STATELESS',
    XXA: 'STATELESS PERSON ARTICLE 1/1954',
    SDN: 'SUDAN',
    SUR: 'SURINAME',
    SJM: 'SVALBARD AND JAN MAYEN ISLANDS',
    SWZ: 'SWAZILAND',
    SWE: 'SWEDEN',
    CHE: 'SWITZERLAND',
    SYR: 'SYRIA',
    TWN: 'TAIWAN',
    TJK: 'TAJIKISTAN',
    TZA: 'TANZANIA',
    THA: 'THAILAND',
    FYR: 'THE FORMER YUGOSLAV REP. OF MACEDONIA',
    ZZB: 'TIDAK DINYATAKAN OLEH KLN',
    TLS: 'TIMOR LESTE',
    TMP: 'TIMOR LESTE',
    TGO: 'TOGO',
    TKL: 'TOKELAU',
    TON: 'TONGA',
    TTO: 'TRINIDAD AND TOBAGO',
    TUN: 'TUNISIA',
    TUR: 'TURKEY',
    TKM: 'TURKMENISTAN',
    TCA: 'TURKS AND CAICOS ISLANDS',
    TUV: 'TUVALU',
    UGA: 'UGANDA',
    GBD: 'UK-DEPENDENT',
    UKR: 'UKRAINE',
    UNA: 'UN SPECIALIZED AGENCY',
    UNH: 'UNHCR',
    ARE: 'UNITED ARAB EMIRATES',
    GBR: 'UNITED KINGDOM',
    UNO: 'UNITED NATIONS ORGANIZATION',
    UMI: 'UNITED STATES MINOR OUTLYING ISLANDS',
    ZZZ: 'UNKNOWN',
    XXX: 'UNSPECIFIED NATIONALITY',
    URY: 'URUGUAY',
    USA: 'USA',
    UZB: 'UZBEKISTAN',
    VUT: 'VANUATU',
    VEN: 'VENEZUELA',
    VNM: 'VIETNAM',
    VGB: 'VIRGIN ISLANDS (BRITISH)',
    VIR: 'VIRGIN ISLANDS (USA)',
    WLF: 'WALLIS AND FUTURA ISLANDS',
    ESH: 'WESTERN SAHARA',
    YEM: 'YEMEN',
    YUG: 'YUGOSLAVIA',
    ZAR: 'ZAIRE',
    ZMB: 'ZAMBIA',
    ZWE: 'ZIMBABWE',
    ZIM: 'ZIMBABWE',
};

export const countryCode = {
    "AFGHANISTAN": "93",
    "ALBANIA": "355",
    "ALGERIA": "213",
    "AMERICAN SAMOA": "1684",
    "ANDORRA": "376",
    "ANGOLA": "244",
    "ANGUILLA": "1264",
    "ANTARCTICA": "672",
    "ANTIGUA AND BARBUDA": "1268",
    "ARGENTINA": "54",
    "ARMENIA": "374",
    "ARUBA": "297",
    "AUSTRALIA": "61",
    "AUSTRIA": "43",
    "AZERBAIJAN": "994",
    "BAHAMAS": "1242",
    "BAHRAIN": "973",
    "BANGLADESH": "880",
    "BARBADOS": "1246",
    "BELARUS": "375",
    "BELGIUM": "32",
    "BELIZE": "501",
    "BENIN": "229",
    "BERMUDA": "1441",
    "BHUTAN": "975",
    "BOLIVIA": "591",
    "BOSNIA AND HERZEGOVINA": "387",
    "BOTSWANA": "267",
    "BRAZIL": "55",
    "BRITISH INDIAN OCEAN TERRITORY": "246",
    "BRITISH VIRGIN ISLANDS": "1284",
    "BRUNEI": "673",
    "BULGARIA": "359",
    "BURKINA FASO": "226",
    "BURUNDI": "257",
    "CAMBODIA": "855",
    "CAMEROON": "237",
    "CANADA": "1",
    "CAPE VERDE": "238",
    "CAYMAN ISLANDS": "1345",
    "CENTRAL AFRICAN REPUBLIC": "236",
    "CHAD": "235",
    "CHILE": "56",
    "CHINA": "86",
    "CHRISTMAS ISLAND": "61",
    "COCOS ISLANDS": "61",
    "COLOMBIA": "57",
    "COMOROS": "269",
    "COOK ISLANDS": "682",
    "COSTA RICA": "506",
    "CROATIA": "385",
    "CUBA": "53",
    "CURACAO": "599",
    "CYPRUS": "357",
    "CZECH REPUBLIC": "420",
    "DEMOCRATIC REPUBLIC OF THE CONGO": "243",
    "DENMARK": "45",
    "DJIBOUTI": "253",
    "DOMINICA": "1767",
    "DOMINICAN REPUBLIC": "1809",
    "EAST TIMOR": "670",
    "ECUADOR": "593",
    "EGYPT": "20",
    "EL SALVADOR": "503",
    "EQUATORIAL GUINEA": "240",
    "ERITREA": "291",
    "ESTONIA": "372",
    "ETHIOPIA": "251",
    "FALKLAND ISLANDS": "500",
    "FAROE ISLANDS": "298",
    "FIJI": "679",
    "FINLAND": "358",
    "FRANCE": "33",
    "FRENCH POLYNESIA": "689",
    "GABON": "241",
    "GAMBIA": "220",
    "GEORGIA": "995",
    "GERMANY": "49",
    "GHANA": "233",
    "GIBRALTAR": "350",
    "GREECE": "30",
    "GREENLAND": "299",
    "GRENADA": "1473",
    "GUAM": "1671",
    "GUATEMALA": "502",
    "GUERNSEY": "441481",
    "GUINEA": "224",
    "GUINEABISSAU": "245",
    "GUYANA": "592",
    "HAITI": "509",
    "HONDURAS": "504",
    "HONG KONG": "852",
    "HUNGARY": "36",
    "ICELAND": "354",
    "INDIA": "91",
    "INDONESIA": "62",
    "IRAN": "98",
    "IRAQ": "964",
    "IRELAND": "353",
    "ISLE OF MAN": "441624",
    "ISRAEL": "972",
    "ITALY": "39",
    "IVORY COAST": "225",
    "JAMAICA": "1876",
    "JAPAN": "81",
    "JERSEY": "441534",
    "JORDAN": "962",
    "KAZAKHSTAN": "7",
    "KENYA": "254",
    "KIRIBATI": "686",
    "KOSOVO": "383",
    "KUWAIT": "965",
    "KYRGYZSTAN": "996",
    "LAOS": "856",
    "LATVIA": "371",
    "LEBANON": "961",
    "LESOTHO": "266",
    "LIBERIA": "231",
    "LIBYA": "218",
    "LIECHTENSTEIN": "423",
    "LITHUANIA": "370",
    "LUXEMBOURG": "352",
    "MACAU": "853",
    "MACEDONIA": "389",
    "MADAGASCAR": "261",
    "MALAWI": "265",
    "MALAYSIA": "60",
    "MALDIVES": "960",
    "MALI": "223",
    "MALTA": "356",
    "MARSHALL ISLANDS": "692",
    "MAURITANIA": "222",
    "MAURITIUS": "230",
    "MAYOTTE": "262",
    "MEXICO": "52",
    "MICRONESIA": "691",
    "MOLDOVA": "373",
    "MONACO": "377",
    "MONGOLIA": "976",
    "MONTENEGRO": "382",
    "MONTSERRAT": "1664",
    "MOROCCO": "212",
    "MOZAMBIQUE": "258",
    "MYANMAR": "95",
    "NAMIBIA": "264",
    "NAURU": "674",
    "NEPAL": "977",
    "NETHERLANDS": "31",
    "NETHERLANDS ANTILLES": "599",
    "NEW CALEDONIA": "687",
    "NEW ZEALAND": "64",
    "NICARAGUA": "505",
    "NIGER": "227",
    "NIGERIA": "234",
    "NIUE": "683",
    "NORTH KOREA": "850",
    "NORTHERN MARIANA ISLANDS": "1670",
    "NORWAY": "47",
    "OMAN": "968",
    "PAKISTAN": "92",
    "PALAU": "680",
    "PALESTINE": "970",
    "PANAMA": "507",
    "PAPUA NEW GUINEA": "675",
    "PARAGUAY": "595",
    "PERU": "51",
    "PHILIPPINES": "63",
    "POLAND": "48",
    "PORTUGAL": "351",
    "PUERTO RICO": "1787",
    "QATAR": "974",
    "REPUBLIC OF THE CONGO": "242",
    "REUNION": "262",
    "ROMANIA": "40",
    "RUSSIA": "7",
    "RWANDA": "250",
    "SAINT BARTHELEMY": "590",
    "SAINT HELENA": "290",
    "SAINT KITTS AND NEVIS": "1869",
    "SAINT LUCIA": "1758",
    "SAINT MARTIN": "590",
    "SAINT PIERRE AND MIQUELON": "508",
    "SAINT VINCENT AND THE GRENADINES": "1784",
    "SAMOA": "685",
    "SAN MARINO": "378",
    "SAO TOME AND PRINCIPE": "239",
    "SAUDI ARABIA": "966",
    "SENEGAL": "221",
    "SERBIA": "381",
    "SEYCHELLES": "248",
    "SIERRA LEONE": "232",
    "SINGAPORE": "65",
    "SLOVAKIA": "421",
    "SLOVENIA": "386",
    "SOLOMON ISLANDS": "677",
    "SOMALIA": "252",
    "SOUTH AFRICA": "27",
    "SOUTH KOREA": "82",
    "SOUTH SUDAN": "211",
    "SPAIN": "34",
    "SRI LANKA": "94",
    "SUDAN": "249",
    "SURINAME": "597",
    "SWEDEN": "46",
    "SWITZERLAND": "41",
    "SYRIA": "963",
    "TAIWAN": "886",
    "TAJIKISTAN": "992",
    "TANZANIA": "255",
    "THAILAND": "66",
    "TOGO": "228",
    "TONGA": "676",
    "TRINIDAD AND TOBAGO": "1868",
    "TUNISIA": "216",
    "TURKEY": "90",
    "TURKMENISTAN": "993",
    "TUVALU": "688",
    "UGANDA": "256",
    "UKRAINE": "380",
    "UNITED ARAB EMIRATES": "971",
    "UK": "44",
    "USA": "1",
    "URUGUAY": "598",
    "UZBEKISTAN": "998",
    "VANUATU": "678",
    "VATICAN": "39",
    "VENEZUELA": "58",
    "VIETNAM": "84",
    "WALLIS AND FUTUNA": "681",
    "WESTERN SAHARA": "212",
    "YEMEN": "967",
    "ZAMBIA": "260",
    "ZIMBABWE": "263"
};